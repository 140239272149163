import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAppContext } from "../../../Store/AppContext";
import { Image } from "@mui/icons-material";

interface Issuer {
  id: string;
  name: string;
  logo: string;
  website: string;
  senior_citizen_additional_interest_rate: number | null;
  woman_additional_interest_rate: number | null;
}

const CreateFDOrder = ({ accessToken }: { accessToken: string }) => {
  const [storeState, dispatch] = useAppContext();
  const [issuers, setIssuers] = useState<Issuer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchIssuers = async () => {
    try {
      const token = JSON.parse(
        localStorage.getItem("tokens") || "{}"
      ).accessToken;

      const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}v1/user/fixeddeposit/getissuers`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, 
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setIssuers(data.issuers);
    } catch (err) {
      setError("Failed to fetch issuers");
      console.error("Error fetching issuers:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssuers();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div style={{ margin: "2rem" }}>
      <Grid container spacing={1}>
        {issuers.map((issuer, index) => (
          <Grid sx={{ width: "100%", marginBlock: "20px" }} key={issuer.id}>
            <Card
              sx={{
                borderRadius: "16px",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                height: "100%",
                bgcolor: "#fff",
                color: "",
              }}
            >
              <CardContent>
                <Stack
                  direction="row"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                  spacing={2}
                >
                  <Stack
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    <img
                      src={issuer.logo}
                      alt={issuer.name}
                      style={{
                        width: "80px",
                        height: "40px",
                        marginBottom: "1rem",
                        objectFit: "contain",
                      }}
                    />
                    <Stack>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          "@media (min-width: 600px)": {
                            fontSize: "18px",
                          },
                        }}
                        gutterBottom
                      >
                        {issuer.name}
                      </Typography>

                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          mb: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            bgcolor: "#00bd9d",
                            color: "white",
                            fontSize: "8px",
                            width: "max-content",
                            py: "5px",
                            px: "15px",
                            borderRadius: "10px",
                            fontWeight: "600",
                          }}
                        >
                          Recommended
                        </Typography>
                        <Typography
                          sx={{
                            bgcolor: "#00bd9d",
                            color: "white",
                            fontSize: "8px",
                            width: "max-content",
                            py: "5px",
                            px: "15px",
                            borderRadius: "10px",
                            fontWeight: "600",
                          }}
                        >
                          FAAA
                        </Typography>
                        <Typography
                          sx={{
                            bgcolor: "#00bd9d",
                            color: "white",
                            fontSize: "8px",
                            width: "max-content",
                            py: "5px",
                            px: "15px",
                            borderRadius: "10px",
                            fontWeight: "600",
                          }}
                        >
                          AAA
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Button
                      sx={{
                        bgcolor: "#5465ff",
                        color: "white",
                        borderRadius: "10px",
                        fontWeight: "600",
                        fontSize: "14px",
                        border: "1px solid",
                        paddingInline: "10px",
                        display: "none",
                        "&:hover": {
                          background: "white",
                          color: "#5465ff",
                          borderColor: "#5465ff",
                          border: "1px solid",
                        },
                        "@media (min-width: 600px)": {
                          display: "block",
                        },
                      }}
                      onClick={() =>
                        navigate(`/dashboardAdmin/issuer/${issuer.id}`)
                      }
                    >
                      View Details
                    </Button>
                  </Stack>
                </Stack>

                <Stack sx={{ borderTop: "2px solid #F1F1F1" }}>
                  <Stack>
                    <Stack>
                      <Box sx={{ padding: 2, marginLeft: "0px" }}>
                        <Grid
                          container
                          justifyContent="space-between"
                          sx={{
                            alignItems: "center",
                          }}
                        >
                          <Grid item textAlign="center">
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#656565",
                                "@media (min-width: 600px)": {
                                  fontSize: "18px",
                                },
                              }}
                            >
                              Tenure
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "8px",
                                "@media (min-width: 600px)": {
                                  fontSize: "16px",
                                },
                              }}
                            >
                              12 - 60 Months
                            </Typography>
                          </Grid>
                          <Grid item textAlign="center">
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#656565",
                                "@media (min-width: 600px)": {
                                  fontSize: "18px",
                                },
                              }}
                            >
                              Min. Deposit
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "8px",
                                "@media (min-width: 600px)": {
                                  fontSize: "16px",
                                },
                              }}
                            >
                              RS 5000
                            </Typography>
                          </Grid>
                          <Grid item textAlign="center">
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#656565",
                                "@media (min-width: 600px)": {
                                  fontSize: "18px",
                                },
                              }}
                            >
                              Max Deposit
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "8px",
                                "@media (min-width: 600px)": {
                                  fontSize: "16px",
                                },
                              }}
                            >
                              RS 10,00,00,000
                            </Typography>
                          </Grid>
                          <Grid item textAlign="center">
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "10px",
                                color: "#656565",
                                "@media (min-width: 600px)": {
                                  fontSize: "18px",
                                },
                              }}
                            >
                              Lock-in
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "8px",
                                "@media (min-width: 600px)": {
                                  fontSize: "16px",
                                },
                              }}
                            >
                              3 Months (0.3 Y)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Stack>

                  <Stack>
                    <Button
                      sx={{
                        bgcolor: "#5465ff",
                        color: "white",
                        borderRadius: "10px",
                        fontWeight: "600",
                        fontSize: "10px",
                        border: "1px solid",
                        display: "block",
                        "&:hover": {
                          background: "white",
                          color: "#5465ff",
                          borderColor: "#5465ff",
                          border: "1px solid",
                        },
                        "@media (min-width: 600px)": {
                          display: "none",
                        },
                      }}
                      onClick={() =>
                        navigate(`/dashboardAdmin/issuer/${issuer.id}`)
                      }
                    >
                      View Details
                    </Button>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CreateFDOrder;
