import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomeTabs: React.FC = () => {
  const navigate = useNavigate();

  const cardStyles = {
    background: "linear-gradient(180deg, #60C5FF 0%, #007BBD 100%)",
    color: "white",
    borderRadius: "20px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "270px",
    maxWidth: "500px",
  };


  // Button click handlers
  const handleFirstCardClick = () => {
    navigate("/dashboardAdmin/investing");
  };

  const handleSecondCardClick = () => {
    navigate("/dashboardAdmin/fdInvestment");
  };


  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={4} justifyContent="center">
        {/* First Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card  sx={cardStyles}>
            <CardContent>
              <Typography variant="h5"  gutterBottom>
                Mutual Funds
              </Typography>
              <Typography variant="body2" color="white">
                Checkout more Details about your invested Money in Mutual Funds
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ marginTop: 2 }}
              >
                <Button
                  sx={{
                    backgroundColor: "white",
                    color: "#007BBD",
                    marginTop: "60px",
                    width: "100%",
                    fontWeight: "700",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "white", 
                      color: "#007BBD", 
                    },
                  }}
                  onClick={handleFirstCardClick}
                >
                 Checkout Details
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card  sx={cardStyles}>
            <CardContent>
              <Typography variant="h5"  fontWeight="bold" gutterBottom>
                Fixed Deposit
              </Typography>
              <Typography variant="body2" color="white">
                Checkout more Details about your invested Money in Fixed Deposit
              </Typography>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ marginTop: 2 }}
              >
                <Button
                  sx={{
                    backgroundColor: "white",
                    color: "#007BBD",
                    marginTop: "60px",
                    width: "100%",
                    fontWeight: "700",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "white", 
                      color: "#007BBD", 
                    },
                  }}
                  onClick={handleSecondCardClick}
                >
                  Checkout Details
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeTabs;
