import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface ApiResponse {
  id: string;
  status: string;
}

interface CreatePaymentProps {
  accessToken: string; 
}

const paymentModes = [
  { id: 1, label: "UPI", img: "https://i.ibb.co/JqmR6V2/upi.png" },
  { id: 2, label: "NETBANKING", img: "https://i.ibb.co/VNBj8Qj/transfer.png" },
  { id: 3, label: "NEFT_RTGS", img: "https://i.ibb.co/C1dmRL1/bank.png" },
];

const CreatePayment: React.FC<CreatePaymentProps> = ({ accessToken }) => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const state = location.state as { apiResponse?: ApiResponse };
  const apiResponse = state?.apiResponse;
  const params = useParams();

  const applicationId = params.applicationId;
  const [selectedPaymentMode, setSelectedPaymentMode] = useState<number | null>(
    null
  );
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(""); 

  const handlePaymentModeClick = (modeId: number) => {
    setSelectedPaymentMode(modeId);
  };

  const handleProceed = async () => {
    if (selectedPaymentMode === null) {
      alert("Please select a payment mode before proceeding.");
      return;
    }

    const paymentMethod = paymentModes
      .find((mode) => mode.id === selectedPaymentMode)
      ?.label.toLowerCase();

    const payload = {
      payment_method: paymentMethod,
      application_id: applicationId,
      callback_url: "https://www.klarfin.com/",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/fixeddeposit/createpayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Failed to create payment. Please try again."); 
      setOpenSnackbar(true); 
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 600,
        margin: "auto",
        borderRadius: 4,
        boxShadow: 3,
        mt: "50px",
      }}
    >
      <CardContent
        sx={{
          marginInline: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src="https://i.ibb.co/z6zWz13/coins-chart.png"
          alt="Payment"
          sx={{
            width: "320px",
            maxWidth: "600px",
            height: "320px",
            objectFit: "cover",
            marginInline: "auto",
          }}
        />

        <Typography variant="h6" component="div" sx={{ mt: 3 }}>
          Select Payment Mode
        </Typography>

        <List>
          {paymentModes.map((mode) => (
            <ListItem key={mode.id} disablePadding>
              <ListItemButton
                onClick={() => handlePaymentModeClick(mode.id)}
                sx={{
                  backgroundColor:
                    selectedPaymentMode === mode.id ? "#E0F7FA" : "#F9FAFB",
                  borderRadius: 2,
                  boxShadow: 0,
                  mb: 1,
                  gap: "20px",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#E0F7FA",
                  },
                }}
              >
                <Box
                  component="img"
                  src={mode.img}
                  alt="Example Image"
                  sx={{
                    width: "50px",
                    maxWidth: "600px",
                    height: "40px",
                    objectFit: "contain",
                    marginInline: "auto",
                  }}
                />

                <ListItemText
                  primary={mode.label}
                  primaryTypographyProps={{
                    sx: { color: "#0A2540", fontWeight: "bold" },
                  }}
                />

                <ArrowForwardIcon sx={{ color: "#4CAF50" }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Button
          onClick={handleProceed}
          sx={{
            bgcolor: "#4ad66d",
            color: "white",
            borderRadius: "10px",
            fontWeight: "600",
            fontSize: "14px",
            border: "1px solid",
            px: "40px",
            py: "10px",
            width: "100%",
            "&:hover": {
              background: "white",
              color: "#4ad66d",
              borderColor: "#4ad66d",
              border: "1px solid",
            },
            "&.Mui-disabled": {
              bgcolor: "#d3d3d3",
              color: "#a9a9a9",
              border: "1px solid #a9a9a9",
            },
          }}
        >
          Proceed
        </Button>
      </CardContent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000} 
        onClose={() => setOpenSnackbar(false)} 
      >
        <Alert
          onClose={() => setOpenSnackbar(false)} 
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CreatePayment;
